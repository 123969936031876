import React from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import LayoutContainer from '../containers/layout-container';
const About = loadable(() => import('../components/about'));
const Nav = loadable(() => import('../components/nav'));
const Contact = loadable(() => import('../components/contact'));
const Services = loadable(() => import('../components/services'));
const Hero = loadable(() => import('../components/hero'));
const ProjectSection = loadable(() => import('../components/projectSection'));
const BlogSection = loadable(() => import('../components/blogSection'));

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <LayoutContainer>
        <GraphQLErrorList errors={errors} />
      </LayoutContainer>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.',
    );
  }

  return (
    <LayoutContainer>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <main>
          <Hero />
          <Nav />
          <About />
          <Services />
          <ProjectSection />
          <BlogSection />
          <Contact />
        </main>
      </Container>
    </LayoutContainer>
  );
};

export default IndexPage;
